<template>
  <writing-dialog
    :title="$t('miniApp.getAppUrl')"
    :button="$t('button.copy')"
    :show-both="false"
    @closeDialog="$emit('exit')"
    @confirmBtn="handleConfirm"
  >
    <input
      ref="shareInputRef"
      :value="shareLink"
      type="text"
      class="input name-input"
    >
  </writing-dialog>
</template>

<script>
import { defineComponent, ref, nextTick } from '@vue/composition-api'
import { useI18n } from '@/utils/composable/i18n'
import WritingDialog from '@/components/dialog/WritingDialog'
import { Message } from 'element-ui'

export default defineComponent({
  name: 'ShareAppDialog',
  components: {
    WritingDialog
  },
  props: {
    shareLink: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const shareInputRef = ref()

    const { t } = useI18n()

    async function handleConfirm () {
      let input = shareInputRef.value
      input.select()
      /* For mobile devices */
      input.setSelectionRange(0, 99999)
      document.execCommand('copy')

      Message({
        message: t('message.copiedToBoard'),
        type: 'success',
        duration: 3 * 1000,
        showClose: true
      })

      await nextTick()
      emit('exit')
    }

    return {
      shareInputRef,
      handleConfirm
    }
  }
})
</script>

<style lang="scss" scoped>
.name-input {
  margin: 24px 0;
  padding-bottom: 8px;
}
</style>
