<template>
  <div class="full-page-dialog">
    <div class="dialog-container">
      <div class="dialog-title">
        {{ title }}
      </div>
      <spinner v-if="isLoading" />
      <template v-else>
        <template v-if="mode === 'copy'">
          <card-with-left-title :title="$t('miniApp.miniAppName')">
            <div class="input-block">
              <input-verify
                v-validate="`required|max:${max}`"
                v-model="appBasicSettings.name"
                :placeholder="$t('miniApp.pleaseEnterName')"
                :is-disabled="isPending"
                type="text"
                name="appSettingsName"
              />
            </div>
          </card-with-left-title>
          <card-with-left-title :title="$t('miniApp.form.assignGroup')">
            <div class="input-block">
              <default-select
                v-model="selectedGroupId"
                :option-list="groupOptionList"
                :placeholder="$t('miniApp.form.selectGroup')"
                :disabled="isPending"
              />
            </div>
          </card-with-left-title>
        </template>

        <data-frame-list
          ref="dataFrameListRef"
          :group-id="selectedGroupId"
          :original-data-frame-list="originalDataFrameList"
          :disabled="isPending"
          @finish="statusOfFinish.splice(0, 1, true)"
          @unfinish="statusOfFinish.splice(0, 1, false)"
        />
        <model-list
          v-if="originalModelList.length"
          ref="modelListRef"
          :group-id="selectedGroupId"
          :original-model-list="originalModelList"
          :disabled="isPending"
          @finish="statusOfFinish.splice(1, 1, true)"
          @unfinish="statusOfFinish.splice(1, 1, false)"
        />
      </template>

      <div class="dialog-footer">
        <div class="dialog-button-block">
          <button
            class="btn btn-outline"
            :disabled="isLoading || isPending"
            @click="handleCancel"
          >
            {{ $t('button.cancel') }}
          </button>
          <button
            class="btn btn-default"
            :disabled="isLoading || !isFinished || isPending || isInitFailed"
            @click="handleSave"
          >
            {{ textOfNext }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import { useMapGetters, useMapState } from '@/utils/composable/vuex'
import { useI18n } from '@/utils/composable/i18n'
import { useValidator } from '@/utils/composable/validator'
import {
  getMiniAppInfo,
  getAppDataSettings,
  copyApp,
  updateAppDataSettings
} from '@/API/MiniApp'
import { useMiniAppModuleStore } from '@/modules/miniApp'
import { useEventLog } from '@/utils/composable/eventLog'
import { Message } from 'element-ui'
import CardWithLeftTitle from '../importFlow/steps/stepConfigureDataSettings/components/CardWithLeftTitle.vue'
import DataFrameList from '../importFlow/steps/stepConfigureDataSettings/components/DataFrameList.vue'
import ModelList from '../importFlow/steps/stepConfigureDataSettings/components/ModelList.vue'
import InputVerify from '@/components/InputVerify'
import DefaultSelect from '@/components/select/DefaultSelect'

export default defineComponent({
  name: 'DataSettingsDialog',
  components: {
    InputVerify,
    CardWithLeftTitle,
    DataFrameList,
    ModelList,
    DefaultSelect
  },
  props: {
    groupId: {
      type: Number,
      required: true
    },
    appId: {
      type: Number,
      required: true
    },
    mode: {
      type: String,
      required: true,
      validator: (value) => ['update', 'copy']
    }
  },
  /**
   * @param {
      {
        groupId: number;
        appId: number;
        mode: 'update' | 'copy';
      }
   * } props
   */
  setup (props, { emit }) {
    const { t } = useI18n()
    const title = computed(() => {
      return {
        update: t('miniApp.settingsOfAppDataSource'),
        copy: t('miniApp.settingsOfCopyApp')
      }[props.mode]
    })
    const textOfNext = computed(() => {
      return {
        update: t('button.save'),
        copy: t('button.confirm')
      }[props.mode]
    })
    const { fieldCommonMaxLength: max } = useMapGetters('validation', ['fieldCommonMaxLength'])

    const { groupList } = useMapState('userManagement', ['groupList'])
    const groupOptionList = computed(() => {
      return groupList.value.map(({ groupId, groupName }) => ({
        name: groupName,
        value: groupId
      }))
    })

    const isLoading = ref(false)
    const isInitFailed = ref(false)
    const selectedGroupId = ref(props.groupId)

    const appBasicSettings = ref({
      name: '',
      description: '',
      icon: '',
      timeZone: ''
    })
    async function loadAppBasicSettings () {
      if (props.mode === 'update') return

      const {
        name,
        description,
        icon,
        settings: {
          editModeData: {
            timeZone
          }
        }
      } = await getMiniAppInfo(props.appId)
      appBasicSettings.value = {
        name,
        description,
        icon,
        timeZone
      }
    }

    const appDataSettings = ref(null)
    async function loadAppDataSettings () {
      const { dataSettings } = await getAppDataSettings(props.appId)
      appDataSettings.value = dataSettings
    }

    onMounted(async () => {
      isLoading.value = true
      await Promise.all([
        loadAppBasicSettings(),
        loadAppDataSettings()
      ])
        .catch((error) => {
          isInitFailed.value = true
        })
      isLoading.value = false
    })

    const originalDataFrameList = computed(() => appDataSettings.value?.dataFrameList ?? [])
    const originalModelList = computed(() => appDataSettings.value?.modelList ?? [])

    const statusOfFinish = ref([
      false,
      originalModelList.value.length === 0
    ])
    const isFinished = computed(() => statusOfFinish.value.every(Boolean))

    const dataFrameListRef = ref()
    const modelListRef = ref()

    function getDataSettingsData () {
      return {
        dataSettings: {
          dataFrameList: dataFrameListRef.value.getUpdateData() ?? [],
          modelList: modelListRef.value?.getUpdateData() ?? []
        }
      }
    }

    function handleCancel () {
      emit('exit')
    }

    const validator = useValidator()
    const { addPendingAppInfoToList } = useMiniAppModuleStore()

    const isPending = ref(false)

    const { appEventLog } = useEventLog()

    async function handleSave () {
      const valid = await validator.validateAll()
      const [dataSettings, basicSettings] = [getDataSettingsData(), appBasicSettings.value]

      if (!valid || dataSettings == null) return

      const request = {
        update: () => updateAppDataSettings(props.appId, dataSettings),
        copy: () => copyApp(props.appId, {
          ...dataSettings,
          ...basicSettings,
          groupId: selectedGroupId.value
        })
      }[props.mode]

      try {
        isPending.value = true
        const { newAppId: appId = props.appId } = await request()
        addPendingAppInfoToList({
          id: appId,
          type: props.mode
        })
        Message({
          message: {
            update: t('miniApp.notificationMessage.startUpdate', { appName: basicSettings.name }),
            copy: t('miniApp.notificationMessage.startCopy', { appName: basicSettings.name })
          }[props.mode],
          type: 'success',
          duration: 3 * 1000,
          showClose: true
        })

        if (props.mode === 'copy') {
          appEventLog('copyApp', { appId: props.appId })
        } else if (props.mode === 'update') {
          appEventLog('updateAppInfo', { appId: props.appId })
        }
        emit('done')
        emit('exit')
      } catch (error) {
        console.error(error)
      } finally {
        isPending.value = false
      }
    }

    return {
      dataFrameListRef,
      modelListRef,
      isLoading,
      isInitFailed,
      isPending,
      title,
      textOfNext,
      max,
      selectedGroupId,
      groupOptionList,
      appBasicSettings,
      originalDataFrameList,
      originalModelList,
      statusOfFinish,
      isFinished,
      handleCancel,
      handleSave
    }
  }
})
</script>

<style lang="scss" scoped>
.input-block {
  padding-bottom: 8px;
  width: 40%;

  ::v-deep .error-text {
    opacity: 1;
    visibility: visible;
  }

  ::v-deep .input-verify-text {
    margin-bottom: 0;
  }

  ::v-deep .input-error {
    bottom: unset;
  }

  ::v-deep .default-select {
    padding-bottom: 0;
  }
}
</style>
