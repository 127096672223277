<template>
  <div class="full-page-dialog">
    <div class="dialog-container">
      <import-flow
        @done="handleDone"
        @exit="$emit('exit')"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { useEventLog } from '@/utils/composable/eventLog'
import ImportFlow from './ImportFlow.vue'

export default defineComponent({
  name: 'ImportMiniAppDialog',
  components: {
    ImportFlow
  },
  setup () {
    const { appEventLog } = useEventLog()

    function handleDone () {
      appEventLog('importApp')
      this.$emit('done')
    }

    return {
      handleDone
    }
  }
})
</script>
