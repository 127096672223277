<template>
  <div class="single-mini-app mini-app">
    <div
      class="mini-app__info"
      :class="{ 'mini-app__info--failed': miniApp.status === MiniAppStatus.Fail }"
    >
      <div class="mini-app__badge">
        <svg-icon
          :icon-class="miniApp.icon"
          class="icon mini-app__icon"
        />
      </div>
      <div class="mini-app__name">
        {{ miniApp.name }}
      </div>
      <div class="mini-app__description">
        {{ miniApp.description }}
      </div>
    </div>
    <template v-if="miniApp.status === MiniAppStatus.Process">
      <spinner />
    </template>
    <template v-else-if="miniApp.status === MiniAppStatus.Fail">
      <div class="failed-msg">
        <svg-icon icon-class="error" />
        {{ $t('editing.buildFailed') }}
      </div>
    </template>
    <template v-else>
      <router-link
        :to="{
          name: 'MiniApp',
          params: { 'mini_app_id': miniApp.id },
          query: { mode: 'edit' }
        }"
        class="link action-link"
      >
        <span>
          <BeginnerGuide
            :content="$t('beginnerGuide.editMiniApp')"
            v-if="showGuide"
          />
          {{ $t('miniApp.editContent') }}
        </span>
      </router-link>
      <a
        href="javascript:void(0);"
        class="link action-link"
        @click="handleOpenMiniApp"
      >{{ $t('miniApp.goToApp') }}</a>
    </template>
    <div class="mini-app__action-block">
      <div class="mini-app__hover-box">
        <svg-icon
          icon-class="more"
          class="icon more-icon"
        />
        <div class="mini-app__popup">
          <div
            v-for="(action, index) in moreActions"
            :key="`action-${index}`"
            class="popup-box"
            :class="{'popup-box--disabled': action.icon === 'copy' && isTriggeredAppLimit}"
            @click.stop="action.doAction"
          >
            <div class="popup-text">
              <svg-icon
                :icon-class="action.icon"
                class="icon "
              />
              {{ action.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api'
import { useRouter, useRoute } from '@/utils/composable/router'
import { useI18n } from '@/utils/composable/i18n'
import { MiniAppStatus, downloadExportedApp } from '@/modules/miniApp/utils'
import BeginnerGuide from '@/components/BeginnerGuide'

export default defineComponent({
  name: 'SingleMiniAppCard',
  components: {
    BeginnerGuide
  },
  props: {
    miniApp: {
      type: Object,
      required: true
    },
    isTriggeredAppLimit: {
      type: Boolean,
      default: false
    },
    showGuide: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    function handleOpenMiniApp () {
      const { params } = route
      const routeData = router.resolve({
        name: 'MiniApp',
        params: {
          ...params,
          mini_app_id: props.miniApp.id
        },
        query: { mode: 'view' }
      })
      window.open(routeData.href, '_blank')
    }

    const isExporting = ref(false)

    const { t } = useI18n()
    const moreActions = computed(() => [
      {
        icon: 'edit',
        text: t('miniApp.editInfo'),
        isShow: props.miniApp.status === MiniAppStatus.Enable,
        doAction: () => emit('showEdit')
      },
      {
        icon: 'share',
        text: t('miniApp.getAppUrl'),
        isShow: props.miniApp.status === MiniAppStatus.Enable,
        doAction: () => emit('showShare')
      },
      {
        icon: 'copy',
        text: t('miniApp.option.copy'),
        isShow: props.miniApp.status === MiniAppStatus.Enable,
        doAction: () => {
          !props.isTriggeredAppLimit && emit('showCopy')
        }
      },
      {
        icon: 'export',
        text: t('miniApp.option.export'),
        isShow: props.miniApp.status === MiniAppStatus.Enable,
        doAction: async () => {
          if (isExporting.value) return
          isExporting.value = true
          await downloadExportedApp(props.miniApp.id, props.miniApp.name)
          isExporting.value = false
        }
      },
      {
        icon: 'delete',
        text: t('miniApp.deleteApp'),
        isShow: true,
        doAction: () => emit('showDelete')
      }
    ].filter(({ isShow }) => isShow))

    return {
      MiniAppStatus,
      handleOpenMiniApp,
      moreActions
    }
  }
})
</script>

<style lang="scss" scoped>
.mini-app {
  background: rgba(0, 0, 0, 0.55);
  border-radius: 8px;
  height: 255px;
  margin-bottom: 16px;
  margin-right: 20px;
  padding: 24px;
  position: relative;
  text-align: center;
  width: 206px;

  .spinner-block {
    padding: 0;
  }

  .failed-msg {
    align-items: center;
    color: #ff5c46;
    display: flex;
    justify-content: center;

    .svg-icon {
      height: 20px;
      width: 20px;
    }
  }

  .action-link {
    >span {
      position: relative;
    }

    .beginner-guide {
      transform: translate(-80%, -50%);
    }
  }

  &__info {
    &--failed {
      opacity: 0.5;
    }
  }

  &__badge {
    background: rgba(255, 255, 255, 0.16);
    border-radius: 50%;
    height: 80px;
    margin: 0 auto 16px;
    position: relative;
    width: 80px;
  }

  &__icon {
    color: #2ad2e2;
    font-size: 40px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__name,
  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__name {
    @include text-hidden;

    font-size: 16px;
    font-weight: 600;
    width: 100%;
  }

  &__description {
    color: #ddd;
    font-size: 13px;
    margin-bottom: 15px;
    min-height: 19px;
  }

  &__action-block {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  &__hover-box {
    align-items: center;
    align-self: flex-end;
    display: flex;
    height: 30px;
    justify-content: center;
    position: relative;
    width: 30px;

    &:hover {
      .mini-app__popup {
        visibility: visible;
      }
    }
  }

  .link {
    display: block;
    font-weight: 600;

    &:first-of-type {
      margin-bottom: 8px;
    }
  }

  .more-icon {
    color: #1eb8c7;
    cursor: pointer;
    height: 22px;
    width: 22px;
  }

  &__popup {
    background: #233131;
    border-radius: 8px;
    bottom: 100%;
    box-shadow: 0 4px 10px rgba(58, 178, 189, 0.5);
    cursor: pointer;
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    right: -20px;
    visibility: hidden;
    width: 160px;

    &::after {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #233131;
      bottom: -10px;
      content: '';
      height: 0;
      position: absolute;
      right: 24px;
      width: 0;
    }

    .popup-box {
      align-items: center;
      color: #a7a7a7;
      display: flex;
      height: 40px;

      &:hover {
        color: #fff;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #384545;
      }

      &--disabled {
        opacity: 0.25;
        cursor: not-allowed;
      }
    }

    .popup-text {
      align-items: center;
      display: flex;
      font-size: 14px;
      font-weight: 600;
      padding: 0 12px;
    }

    .icon {
      color: #00c9dc;
      height: 20px;
      margin-right: 6px;
      width: 20px;
    }
  }
}
</style>
