<template>
  <div class="mini-app-list-page list-page wrapper">
    <h1 class="list-page__title">
      {{ $t('miniApp.application') }}
    </h1>
    <div class="list-page__action-container">
      <button
        class="btn-m btn-default btn-has-icon add-btn"
        :disabled="isTriggeredAppLimit"
        @click="showAddDialog(isTriggeredAppLimit)"
      >
        <BeginnerGuide
          v-if="isGuideMode"
          :content="$t('beginnerGuide.createMiniApp')"
        />
        <svg-icon
          icon-class="plus"
          class="icon"
        />
        {{ $t('miniApp.createNewApplication') }}
      </button>
      <button
        class="btn-m btn-default btn-has-icon add-btn"
        :disabled="isTriggeredAppLimit"
        @click="showImportDialog(isTriggeredAppLimit)"
      >
        <svg-icon
          icon-class="import"
          class="icon"
        />
        {{ $t('miniApp.option.import') }}
      </button>
    </div>
    <spinner
      v-if="isLoading"
      :title="$t('editing.loading')"
    />
    <div v-else>
      <div
        v-if="miniAppList.appBasicDtoList.length"
        class="mini-app-list"
      >
        <single-mini-app-card
          v-for="(miniApp, i) in miniAppList.appBasicDtoList"
          :key="miniApp.id"
          :mini-app="miniApp"
          :is-triggered-app-limit="isTriggeredAppLimit"
          @showEdit="showEditDialog(miniApp.id, miniApp.version)"
          @showDelete="showDeleteDialog(miniApp)"
          @showShare="showShareDialog(miniApp.id)"
          @showCopy="showCopyDialog(miniApp.id, miniApp.version)"
          :show-guide="isGuideMode && i === 0"
        />
      </div>
      <empty-info-block
        v-else
        :msg="$t('miniApp.emptyApplication')"
      />
    </div>

    <import-mini-app-dialog
      v-if="isShowImportDialog"
      @done="fetchData"
      @exit="closeImportDialog"
    />
    <app-editor-dialog
      v-if="isShowEditorDialog"
      :group-id="groupId"
      :edit="isEditMode"
      :init-data="editingAppInfo"
      @done="fetchData"
      @exit="closeEditorDialog"
    />
    <data-settings-dialog
      v-if="isShowCopyDialog"
      :group-id="groupId"
      :app-id="copyingAppId"
      mode="copy"
      @done="fetchData"
      @exit="closeCopyDialog"
    />
    <delete-app-dialog
      v-if="isShowDeleteDialog"
      :app-id="deletingApp.id"
      :app-name="deletingApp.name"
      @done="fetchData"
      @exit="closeDeleteDialog"
    />
    <share-app-dialog
      v-if="isShowShareDialog"
      :share-link="shareLink"
      @exit="closeShareDialog"
    />
  </div>
</template>
<script>
import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import { useMapState, useMapGetters } from '@/utils/composable/vuex'
import { migrateSettingVersion } from '@/modules/miniApp/utils.js'
import { migrationConfirm } from '@/modules/miniApp/composable/migrationConfirm'
import moment from 'moment-timezone'
import SingleMiniAppCard from './components/SingleMiniAppCard'
import AppEditorDialog from './components/AppEditorDialog.vue'
import ImportMiniAppDialog from '@/modules/miniApp/importFlow/components/ImportMiniAppDialog.vue'
import DataSettingsDialog from '@/modules/miniApp/components/DataSettingsDialog.vue'
import DeleteAppDialog from './components/DeleteAppDialog.vue'
import ShareAppDialog from './components/ShareAppDialog.vue'
import EmptyInfoBlock from '@/components/EmptyInfoBlock'
import InputVerify from '@/components/InputVerify'
import TimeZoneSelect from '@/components/select/TimeZoneSelect.vue'
import BeginnerGuide from '@/components/BeginnerGuide'
import { getMiniAppInfo } from '@/API/MiniApp'
import { useMiniAppModuleContext } from '@/modules/miniApp'

const createMiniAppInfo = () => ({
  // TODO: 抽出 schema
  name: null,
  icon: null,
  description: null,
  status: 'Enable',
  settings: {
    version: '2.0.7',
    isPublished: false,
    editModeData: {
      name: null,
      config: {
        timeZone: moment.tz.guess(),
        isSideNavPin: true
      },
      dashboards: [],
      warningModule: {
        activate: false,
        updateFrequency: '* * * * *',
        conditions: [] // { conditionId: 1, relatedDashboardId: null }
      }
    },
    viewModeData: null
  }
})

function useEditorDialog () {
  const isShowEditorDialog = ref(false)
  const isEditMode = ref(false)
  const editingAppInfo = ref(null)

  function showAddDialog (isTriggeredAppLimit = false) {
    if (isTriggeredAppLimit) return
    editingAppInfo.value = createMiniAppInfo()
    isEditMode.value = false
    isShowEditorDialog.value = true
  }
  async function showEditDialog (appId, appVersion) {
    if (!appId) return

    const res = await migrationConfirm(appId)
    if (res) {
      await migrateSettingVersion(appId, appVersion)
      editingAppInfo.value = await getMiniAppInfo(appId)
      isEditMode.value = true
      isShowEditorDialog.value = true
    }
  }

  function closeEditorDialog () {
    isShowEditorDialog.value = false
    editingAppInfo.value = null
  }

  return {
    isShowEditorDialog,
    isEditMode,
    editingAppInfo,
    showAddDialog,
    showEditDialog,
    closeEditorDialog
  }
}

function useImportDialog () {
  const isShowImportDialog = ref(false)

  function showImportDialog (isTriggeredAppLimit = false) {
    if (isTriggeredAppLimit) return
    isShowImportDialog.value = true
  }

  function closeImportDialog () {
    isShowImportDialog.value = false
  }

  return {
    isShowImportDialog,
    showImportDialog,
    closeImportDialog
  }
}

function useCopyDialog () {
  const copyingAppId = ref(null)
  const isShowCopyDialog = computed(() => !!copyingAppId.value)

  async function showCopyDialog (appId, appVersion) {
    const res = await migrationConfirm(appId)
    if (res) {
      await migrateSettingVersion(appId, appVersion)
      copyingAppId.value = appId
    }
  }

  function closeCopyDialog () {
    copyingAppId.value = null
  }

  return {
    isShowCopyDialog,
    copyingAppId,
    showCopyDialog,
    closeCopyDialog
  }
}

function useShareDialog () {
  const {
    getCurrentAccountId: accountId,
    getCurrentGroupId: groupId
  } = useMapGetters('userManagement', [
    'getCurrentAccountId',
    'getCurrentGroupId'
  ])
  const shareLink = ref(null)
  const isShowShareDialog = computed(() => !!shareLink.value)

  function showShareDialog (appId) {
    shareLink.value = `${window.location.origin}/account/${accountId.value}/group/${groupId.value}/mini-apps/${appId}?mode=view`
  }

  function closeShareDialog () {
    shareLink.value = null
  }

  return {
    isShowShareDialog,
    shareLink,
    showShareDialog,
    closeShareDialog
  }
}

function useDeleteDialog () {
  const deletingApp = ref(null)
  const isShowDeleteDialog = computed(() => !!deletingApp.value)

  function showDeleteDialog (app) {
    deletingApp.value = app
  }

  function closeDeleteDialog () {
    deletingApp.value = null
  }

  return {
    isShowDeleteDialog,
    deletingApp,
    showDeleteDialog,
    closeDeleteDialog
  }
}

export default defineComponent({
  name: 'MiniAppList',
  components: {
    SingleMiniAppCard,
    AppEditorDialog,
    ImportMiniAppDialog,
    DataSettingsDialog,
    DeleteAppDialog,
    ShareAppDialog,
    EmptyInfoBlock,
    InputVerify,
    TimeZoneSelect,
    BeginnerGuide
  },
  setup () {
    const { isGuideMode } = useMapState(['isGuideMode'])
    const { getCurrentGroupId: groupId } = useMapGetters('userManagement', ['getCurrentGroupId'])
    const {
      fetchMiniAppList,
      miniAppList
    } = useMiniAppModuleContext()

    const isTriggeredAppLimit = computed(() => {
      return miniAppList.value.appLimit !== -1 && miniAppList.value.appBasicDtoList.length >= miniAppList.value.appLimit
    })

    const isLoading = ref(false)
    async function fetchData () {
      try {
        isLoading.value = true
        await fetchMiniAppList()
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    onMounted(() => {
      fetchData()
    })

    return {
      isGuideMode,
      groupId,
      isLoading,
      miniAppList,
      fetchData,
      isTriggeredAppLimit,
      ...useEditorDialog(),
      ...useImportDialog(),
      ...useCopyDialog(),
      ...useShareDialog(),
      ...useDeleteDialog()
    }
  }
})
</script>
<style lang="scss" scoped>
.list-page {
  padding: 40px 24px;

  &__title {
    margin-bottom: 8px;
    margin-top: 0;
  }

  &__action-container {
    display: flex;
    margin-bottom: 16px;

    button:not(:last-of-type) {
      margin-right: 1rem;
      position: relative;
    }
  }

  .mini-app-list {
    display: flex;
    flex-wrap: wrap;
  }

  ::v-deep .dialog-inner-box {
    .dialog-select-text {
      margin-bottom: 24px;
    }

    .input-verify-text,
    .el-select {
      margin-bottom: 26px;
    }

    .input-error {
      bottom: 9px;
    }
  }

  ::v-deep .el-select {
    display: block;
  }

  .dialog {
    &__input-block,
    &__icons-block {
      text-align: left;
    }

    &__label {
      color: #ccc;
      font-size: 14px;
      font-weight: 600;
    }

    &__icons-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 8px;
    }

    &__icons-block {
      margin-bottom: 15px;
    }

    &__icon-box {
      align-items: center;
      border: 2px solid #485454;
      border-radius: 12px;
      color: #ccc;
      cursor: pointer;
      display: flex;
      font-size: 32px;
      height: 75px;
      justify-content: center;
      margin-bottom: 12px;
      transition: all 0.1s linear;
      width: 84px;

      &.active,
      &:hover {
        background: #485454;
        border: 2px solid #2ad2e2;
        color: #fff;
      }
    }

    &__icon-box-radio {
      display: none;
    }
  }
}

</style>
