<template>
  <writing-dialog
    :title="edit ? $t('miniApp.editApp') : $t('miniApp.createNewApplication')"
    :button="edit ? $t('button.change') : $t('button.create')"
    :show-both="true"
    :is-loading="isProcessing"
    @closeDialog="$emit('exit')"
    @confirmBtn="edit ? handleEdit() : handleAdd()"
  >
    <app-settings-form
      ref="form"
      :init-data="initFormData"
      :disabled="isProcessing"
    />
  </writing-dialog>
</template>

<script>
import moment from 'moment-timezone'
import { defineComponent, ref, computed } from '@vue/composition-api'
import { useI18n } from '@/utils/composable/i18n'
import { useEventLog } from '@/utils/composable/eventLog'
import {
  createApp,
  updateAppSetting
} from '@/API/MiniApp'
import { updateAlertTimeZone } from '@/API/Alert'
import { Message } from 'element-ui'
import WritingDialog from '@/components/dialog/WritingDialog'
import AppSettingsForm from './AppSettingsForm.vue'

export default defineComponent({
  name: 'AppEditorDialog',
  components: {
    WritingDialog,
    AppSettingsForm
  },
  props: {
    groupId: {
      type: Number,
      required: true
    },
    initData: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const { appEventLog } = useEventLog()

    const appData = ref(JSON.parse(JSON.stringify(props.initData)))
    const initFormData = computed(() => {
      return {
        name: appData.value.name ?? '',
        description: appData.value.description ?? '',
        icon: appData.value.icon ?? '',
        timeZone: appData.value.settings.editModeData.config.timeZone ?? moment.tz.guess()
      }
    })

    const form = ref()

    const isProcessing = ref(false)

    function updateAppDataByFormData (formData, isEdit = false) {
      const {
        name,
        description,
        icon,
        timeZone
      } = formData
      appData.value.name = name
      appData.value.description = description
      appData.value.icon = icon
      appData.value.settings.editModeData.config.timeZone = timeZone

      if (!isEdit) {
        appData.value.settings.editModeData.name = name
      }
    }

    const { t } = useI18n()

    async function handleAdd () {
      const formData = await form.value.getFormData()
      if (formData === null) return

      isProcessing.value = true

      updateAppDataByFormData(formData)

      appData.value.settings.editModeData.name = formData.name

      try {
        await createApp({
          ...appData.value,
          groupId: this.groupId
        })

        Message({
          message: t('message.createdSuccessfully'),
          type: 'success',
          duration: 3 * 1000,
          showClose: true
        })

        appEventLog('createApp')
        emit('done')
        emit('exit')
      } catch (e) {
        console.log(e)
      }
      isProcessing.value = false
    }

    async function updateAppWarningTimeZone () {
      // 更新示警條件 timezone & 更新 App 設定
      let appWarningConditions = appData.value.settings.editModeData.warningModule.conditions
      if (appWarningConditions && appWarningConditions.length > 0) {
        const updateConfig = {
          conditionIds: appWarningConditions.map((item) => item.id),
          groupId: props.groupId,
          timeZone: appData.value.settings.editModeData.config.timeZone
        }
        await updateAlertTimeZone(updateConfig)
      }
    }
    async function handleEdit () {
      try {
        const formData = await form.value.getFormData()
        if (formData === null) return

        isProcessing.value = true

        updateAppDataByFormData(formData, true)

        await Promise.all([
          updateAppSetting(appData.value.id, appData.value),
          updateAppWarningTimeZone()
        ])

        Message({
          message: t('message.saveSuccess'),
          type: 'success',
          duration: 3 * 1000,
          showClose: true
        })

        appEventLog('updateAppInfo', { appId: appData.value.id })
        emit('done')
        emit('exit')
      } catch (e) {
        console.log(e)
      }
      isProcessing.value = false
    }

    return {
      form,
      initFormData,
      isProcessing,
      handleAdd,
      handleEdit
    }
  }
})
</script>
