import { capitalize } from 'lodash'
import eventLog from '@/API/EventLog'

export default function dispatchAppEventLog (type, { userName, userId, groupId, appId, ...args }) {
  switch (type) {
    case 'importApp':
      eventLog('FE_MINIAPP_IMPORT', `${userName}_${userId}`, `Import app to group(id:${groupId})`)
      break
    case 'changeDataSource':
      eventLog('FE_MINIAPP_CHANGE_DATA_SOURCE', `${userName}_${userId}`, `Change app(id:${appId}) data source in group(id:${groupId})`)
      break
    case 'copyApp':
      eventLog('FE_MINIAPP_COPY', `${userName}_${userId}`, `Copy app from app(id:${appId}) to group(id:${groupId})`)
      break
    case 'updateAppInfo':
      eventLog('FE_MINIAPP_UPDATE_INFO', `${userName}_${userId}`, `Update app(id:${appId}) info in group(id:${groupId})`)
      break
    case 'createApp':
      eventLog('FE_MINIAPP_CREATE_APP', `${userName}_${userId}`, `Create app in group(id:${groupId})`)
      break
    case 'deleteApp':
      eventLog('FE_MINIAPP_DELETE_APP', `${userName}_${userId}`, `Delete app(id:${appId}) in group(id:${groupId})`)
      break
    case 'migration':
      eventLog('FE_MINIAPP_MIGRATION', `${userName}_${userId}`, `Migrate app(id:${appId}) in group(id:${groupId}) to version ${args.version}`)
      break
    case 'updateAppName':
      eventLog('FE_MINIAPP_UPDATE_APP_NAME', `${userName}_${userId}`, `Change app(id:${appId}) name in group(id:${groupId}), from ${args.oldName} to ${args.newName}`)
      break
    case 'publishApp':
      eventLog('FE_MINIAPP_PUBLISH', `${userName}_${userId}`, `Publish app(id:${appId}) in group(id:${groupId})`)
      break
    case 'unpublishApp':
      eventLog('FE_MINIAPP_UNPUBLISH', `${userName}_${userId}`, `Unpublish app(id:${appId}) in group(id:${groupId})`)
      break
    case 'createDashboard':
      eventLog('FE_MINIAPP_CREATE_DASHBOARD', `${userName}_${userId}`, `Create new dashboard(name:${args.dashboardName}) in app(id:${appId}) in group(id:${groupId})`)
      break
    case 'deleteDashboard':
      eventLog('FE_MINIAPP_DELETE_DASHBOARD', `${userName}_${userId}`, `Delete dashboard(name:${args.dashboardName}) in app(id:${appId}) in group(id:${groupId})`)
      break
    case 'updateDashboardName':
      eventLog('FE_MINIAPP_UPDATE_DASHBOARD_NAME', `${userName}_${userId}`, `Change dashboard name from ${args.oldName} to ${args.newName} in app(id:${appId}) in group(id:${groupId})`)
      break
    case 'changeDashboardOrder':
      eventLog('FE_MINIAPP_CHANGE_DASHBOARD_ORDER', `${userName}_${userId}`, `Change dashboard order in app(id:${appId}) in group(id:${groupId})`)
      break
    case 'createCondition':
    case 'deleteCondition':
    case 'updateCondition':
      eventLog(`FE_MINIAPP_${type.replace('Condition', '').toUpperCase()}_CONDITION`, `${userName}_${userId}`, `${capitalize(type.replace('Condition', ''))} condition(id:${args.conditionId}) in app(id:${appId}) in group(id:${groupId})`)
      break
    case 'activeAllConditions':
    case 'deactivateAllConditions':
      eventLog(`FE_MINIAPP_${type.replace('AllConditions', '').toUpperCase()}_CONDITIONS`, `${userName}_${userId}`, `${capitalize(type.replace('AllConditions', ''))} conditions in app(id:${appId}) in group(id:${groupId})`)
      break
    case 'createYAxis':
      // 建立 Filter
      eventLog('FE_MINIAPP_CREATE_Y_AXIS', `${userName}_${userId}`, `Create YAxis in app(id:${appId}) in group(id:${groupId})`)
      break
    case 'createFilter':
      // 建立 Filter & Controller
      eventLog('FE_MINIAPP_CREATE_FILTER', `${userName}_${userId}`, `Create filter in app(id:${appId}) in group(id:${groupId})`)
      break
    case 'createComponent':
      eventLog('FE_MINIAPP_CREATE_COMPONENT', `${userName}_${userId}`, `Create component(name:${args.name}) in dashboard(name:${args.dashboard}) at app(id:${appId}) in group(id:${groupId})`)
      break
    case 'removeComponent':
      eventLog('FE_MINIAPP_REMOVE_COMPONENT', `${userName}_${userId}`, `Remove component(name:${args.name}) in dashboard(name:${args.dashboard}) at app(id:${appId}) in group(id:${groupId})`)
      break
    case 'createMonitor':
      eventLog('FE_MINIAPP_CREATE_MONITOR', `${userName}_${userId}`, `Create monitor in dashboard(name:${args.dashboard}) at app(id:${appId}) in group(id:${groupId})`)
      break
    case 'createUnhandledAbnormal':
      eventLog('FE_MINIAPP_CREATE_UNHANDLED_ABNORMAL', `${userName}_${userId}`, `Create unhandled abnormal statistics in dashboard(name:${args.dashboard}) at app(id:${appId}) in group(id:${groupId})`)
      break
    case 'createHandledAbnormal':
      eventLog('FE_MINIAPP_CREATE_HANDLED_ABNORMAL', `${userName}_${userId}`, `Create handled abnormal statistics in dashboard(name:${args.dashboard}) at app(id:${appId}) in group(id:${groupId})`)
      break
    case 'updateLayout':
      eventLog('FE_MINIAPP_UPDATE_LAYOUT', `${userName}_${userId}`, `Update layout in dashboard(name:${args.dashboard}) at app(id:${appId}) in group(id:${groupId})`)
      break
    case 'updateComponent':
      eventLog('FE_MINIAPP_UPDATE_COMPONENT', `${userName}_${userId}`, `Update component(name:${args.name}) in dashboard(name:${args.dashboard}) at app(id:${appId}) in group(id:${groupId})`)
      break
    default:
      console.error(`console type:${type} is not exist`)
      break
  }
}
