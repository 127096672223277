import { defineContext } from '@/utils/composable/context'
import { useMapState, useMapGetters } from '@/utils/composable/vuex'
import { useRoute } from '@/utils/composable/router'
import dispatchAppEventLog from './miniApp'

export const {
  registerContext: registerEventLog,
  useContext: useEventLog
} = defineContext({
  namespace: 'EVENT_LOG',
  createContext () {
    const {
      userName: stateUserName,
      userId: stateUserId
    } = useMapState('userManagement', ['userName', 'userId'])
    const route = useRoute()
    const { currentGroupId } = useMapGetters('userManagement', {
      currentGroupId: 'getCurrentGroupId'
    })

    const appEventLog = (type, { userName, userId, groupId, appId, ...args } = {}) => {
      const option = {
        userName: userName ?? stateUserName.value,
        userId: userId ?? stateUserId.value,
        groupId: groupId ?? currentGroupId.value,
        appId: appId ?? route.params.app_id,
        ...args
      }
      dispatchAppEventLog(type, option)
    }

    return {
      appEventLog
    }
  }
})
