<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div class="flow">
      <step-status-block
        :step-title-list="stepTitleList"
        :current-step-title="currentStepTitle"
        :current-step-index="currentStepIndex"
      />

      <transition
        name="fade"
        mode="out-in"
      >
        <component
          :is="currentStepComponent"
          v-on="{
            ...$listeners
          }"
        />
      </transition>
    </div>
  </transition>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { registerImportFlowContext } from '../composable'

import StepStatusBlock from '@/modules/shared/flow/components/StepStatusBlock.vue'

export default defineComponent({
  name: 'ImportFlow',
  components: {
    StepStatusBlock
  },
  setup (props, { emit }) {
    const context = registerImportFlowContext({ emit })
    return context
  }
})
</script>

<style lang="scss" scoped>
.flow {
  margin-bottom: 64px;
}
</style>
