<template>
  <decide-dialog
    :is-processing="isProcessing"
    :title="`${$t('editing.confirmDelete')} ${appName}？`"
    :type="'delete'"
    @closeDialog="$emit('exit')"
    @confirmBtn="handleConfirm"
  />
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import { useEventLog } from '@/utils/composable/eventLog'
import { deleteMiniApp } from '@/API/MiniApp'
import DecideDialog from '@/components/dialog/DecideDialog'

export default defineComponent({
  name: 'DeleteAppDialog',
  components: {
    DecideDialog
  },
  props: {
    appId: {
      type: Number,
      required: true
    },
    appName: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const isProcessing = ref(false)
    const { appEventLog } = useEventLog()

    async function handleConfirm () {
      try {
        isProcessing.value = true
        await deleteMiniApp(props.appId)
      } catch (error) {
        console.error(error)
      } finally {
        isProcessing.value = false
        appEventLog('deleteApp', { appId: props.appId })
        emit('done')
        emit('exit')
      }
    }

    return {
      isProcessing,
      handleConfirm
    }
  }
})
</script>
