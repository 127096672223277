import request from '@/utils/publicRequest'

/**
 * 創立 event log
 * @param {string} event 事件類型, 前端以 FE_ 開頭
 * @param {string} identify 身份識別
 * @param {string} messages 描述
 */
export default function eventLog (event, identify, messages) {
  return request({
    url: '/log/eventLog',
    method: 'POST',
    data: {
      event,
      identify,
      messages
    }
  })
}
